import React from 'react'
import { render } from 'react-dom'
import App from 'app'
import { CookiesProvider } from 'react-cookie'
import CookieConsentBanner from 'components/CookieConsentBanner'



import 'semantic-ui-css/semantic.min.css'
import 'mapbox-gl/dist/mapbox-gl.css'
import "easymde/dist/easymde.min.css";

render(
  <CookiesProvider>
    <CookieConsentBanner />
    <App />
  </CookiesProvider>,
document.getElementById('root'))
