import React from 'react'
import config from 'config'
import PropTypes from 'prop-types'
import {
  Container,
  Image,
  Menu,
} from 'semantic-ui-react'
import {useCookies} from 'react-cookie'
import { Link } from 'react-router-dom'

import './header.scss'

const {debug} = config

function Header() {
  const [cookies, , removeCookie] = useCookies(['cahras-disclaimer'])

  const clearCookie = () => {
    removeCookie('cahras-disclaimer')
  }

  return (
    <Menu id="header">
      <Container>
        <Menu.Item header className="brand" as={Link} to="/">
          <Image src="/assets/images/logo.png" fluid/>
        </Menu.Item>
        <Menu.Menu position="right">
          {debug && cookies['cahras-disclaimer'] !== undefined && (
            <Menu.Item onClick={clearCookie}>
              Clear Cookie
            </Menu.Item>
          )}
        </Menu.Menu>
      </Container>
    </Menu>
  )
}

Header.propTypes =  {
  handleLogout: PropTypes.func,
  loggedIn: PropTypes.bool,
}

Header.defaultProps = {
  loggedIn: false,
  handleLogout: () => {},
}

export default Header
