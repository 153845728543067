import axios from 'axios'
import config from 'config'
import get from 'lodash/get'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import SimpleMDEReact from 'react-simplemde-editor'
// import ReactMarkdown from 'react-markdown'
import {useLocation} from 'react-router-dom'

import {
  Accordion, Card, Divider, Header, Icon, Loader,
} from 'semantic-ui-react'

import Page from 'components/Page'
import './style.scss'

const {apiUrl} = config

const Changelog = (props) => {
  const dataUpdated = get(props, 'data.dataUpdated')
  const reportsUpdated = get(props, 'data.reportsUpdated')
  const message = get(props, 'data.message')

  const panels = [
    {
      key: 1,
      title: 'Release Notes',
      content: {
        content: (
          <React.Fragment>
            {/*<ReactMarkdown source={message} />*/}
            <SimpleMDEReact
              options={{ toolbar: false, status: false}}
              style={{backgroundColor: '#fff'}}
              value={message}
              // onChange={handleMessageChange} // Read-only
              getMdeInstance={(simpleMde) => simpleMde.togglePreview()}
            />
          </React.Fragment>
        )
      }
    }
  ]

  return(
    <React.Fragment>
      <p>
        This list is reviewed and updated on a quarterly basis.
        This list below was developed based on data from {moment(dataUpdated).format('MMMM YYYY')},
        and was last updated in {moment(reportsUpdated).format('MMMM YYYY')}.
      </p>
      { (message && message.trim() !== '') &&
      <Accordion fluid panels={panels} exclusive={false} />
      }
      <Divider hidden />
    </React.Fragment>
  )
}

function CAHRAs() {
  const [loadingReports, toggleLoadingReports] = useState(true)
  const [reports, setReports] = useState([])
  const [isChangelogLoading, toggleChangelogLoading] = useState(true)
  const [changelog, setChangelog] = useState(null)
  const {hash} = useLocation()

  useEffect(() => {
    const fetchLastUpdated = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/v1/changelog/latest`)
        const { message, data_updated, reports_updated } = response.data

        setChangelog({
          message: message,
          dataUpdated: data_updated ? new Date(get(data_updated, '$date')) : null,
          reportsUpdated: reports_updated ? new Date(get(reports_updated, '$date')) : null,
        })

        toggleChangelogLoading(false)
      } catch (err) {
        console.error('err', err)
        console.error('err.stack', err.stack)
      }
    }
    fetchLastUpdated()
  }, [])

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/v1/cahras`)
        const {data} = response
        setReports(data)
        toggleLoadingReports(false)
      } catch (err) {
        console.error('err', err)
        console.error('err.stack', err.stack)
      }
    }
    fetchReports()
  }, [])

  useEffect(() => {
    if (hash === '') {
      return
    }

    if (!loadingReports) {
      window.location = hash
    }
  }, [hash, loadingReports])

  return (
    <Page id="cahras-list">
      <Page.Header>LIST OF CAHRAS</Page.Header>
      <Page.Content>
        <p>For further information on a specific CAHRA, an individual report is available for download by clicking on the CAHRA’s name.</p>

        <p>For your convenience, the following archives (.zip) of CAHRA Reports are now available:</p>
        <ul>
          <li>
            <a href={`${apiUrl}/api/v1/cahras/archive`}>
              All CAHRA Reports
            </a>
          </li>
          {!isChangelogLoading && (
            <li>
              <a href={`${apiUrl}/api/v1/cahras/latest`}>
                Latest CAHRA Reports ({moment(changelog.reportsUpdated).format('MMMM YYYY')})
              </a>
            </li>
          )}
        </ul>

        { /* Display repease notes and dates */ }
        {changelog && (<Changelog data={changelog} />)}

        <p>Should you have any questions about the current indicative, non-exhaustive list of CAHRAs under Regulation (EU) 2017/821, please refer to our <a href="https://www.rand.org/randeurope/research/projects/identifying-conflict-affected-and-high-risk-areas-for-eu-importe.html#faqs" target={'_blank'} rel='noopener noreferrer'>Frequently Asked Questions</a> page.</p>

        {loadingReports && (
          <Loader active inline />
        )}

        {reports.map((record) => {
          const countryCode = get(record, 'country_code')
          const countryName = get(record, 'country_name')
          const recordReports = get(record, 'reports')
          return (
            <div
              className="country-reports"
              id={countryCode}
              key={`accordion-${countryCode}`}
            >
              <Header as="h2">{countryName}</Header>
              <Card.Group
                className="country-report-links"
                itemsPerRow={5}
                stackable
              >
                {recordReports.map((report) => {
                  const reportId = get(get(report, '_id'), '$oid')
                  const isCountryLevel = get(report, 'is_country_level', false)
                  const includedRegions = get(report, 'included_regions', [])
                  return (
                    <React.Fragment key={`country-report-${reportId}`}>
                      {isCountryLevel && (
                        <Card
                          className="report-card"
                          as="a"
                          href={`${apiUrl}/api/v1/cahras/${reportId}/pdf`}
                        >
                          <Card.Content>
                            <Card.Description>
                              <div className="report-label">
                                <Icon name="file alternate outline" />
                                {countryName}
                              </div>
                            </Card.Description>
                          </Card.Content>
                        </Card>
                      )}
                      {!isCountryLevel && (
                        <React.Fragment>
                          {includedRegions.map((region) => {
                            const regionName = get(region, 'name')
                            return (
                              <Card
                                className="report-card"
                                key={`report-region-${regionName}`}
                                as="a"
                                href={`${apiUrl}/api/v1/cahras/${reportId}/pdf`}
                              >
                                <Card.Content>
                                  <Card.Description>
                                    <div className="report-label">
                                      <Icon name="file alternate outline" />
                                      {regionName}
                                    </div>
                                  </Card.Description>
                                </Card.Content>

                              </Card>
                            )
                          })}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )
                })}
              </Card.Group>
            </div>
          )
        })}
      </Page.Content>
    </Page>
  )
}

export default CAHRAs
