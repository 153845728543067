import React from 'react'
import { Link } from 'react-router-dom'
import { Container } from 'semantic-ui-react'

import './style.scss'

const Footer = () => (
  <div id="footer">
    <Container>
      <div className="copyright-information footer-item">
        <p>&copy; European Union {(new Date()).getFullYear()}</p>
        <p>
          This website and its underlying project are to be understood in the broader context of the European Commission's work on trade in general and responsible sourcing of minerals in particular. The objective of this project and of the CAHRAs list presented on this website is to facilitate due diligence efforts of European Union importers of minerals and metals falling under Regulation (EU) 2017/821. Information available through this website should be seen as indicative, not exhaustive, and as constituting only a part of , and is not intended to substitute and cannot replace, the broader due diligence efforts required to be conducted by relevant companies.
        </p>
        <p>
          It must be noted that importers sourcing from areas which are not mentioned on the list presented on this website remain responsible to comply with the due diligence obligations under Regulation 2017/821. Neither RAND Europe nor the European Commission (as contracting authority) assume any responsibility or liability in relation to the contents of this website nor the use of those contents. Equally, the inclusion of an area in the website’s CAHRAs list does not prohibit, imply, or suggest that business activities should not be conducted in this area.
        </p>
        <p>
          This website and its reports are not official documents. Care has been taken to ensure the accuracy of the information contained within the website and the indicative, non-exhaustive list, but no responsibility can be accepted by the European Commission, RAND Europe, or the data providers for any inadvertent errors or omissions. All warranties are excluded to fullest extent permitted by law. Any information in this website not owned by the European Commission may only be re-used with the permission of the applicable content owner.
        </p>
        <p>
          The boundaries, names and designations on this website’s maps do not imply endorsement or acceptance by RAND Europe or the European Commission.
        </p>
      </div>
      <div className="contact">
        <Link to="/contact-us">Contact us</Link>
        <br/>
        <Link to="/terms-of-use">Terms of Use</Link>
        <br/>
        <Link to="/cookie-policy">Cookie Policy</Link>
        <br/>
        <Link to="/privacy-policy">Privacy Policy</Link>
      </div>
    </Container>
  </div>
)

export default Footer
