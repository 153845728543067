import React from 'react'
import Page from 'components/Page'

import './style.scss'

function ContactUs() {
  return (
    <Page id="contact-us">
      <Page.Header>CONTACT US</Page.Header>
      <Page.Content>
        <p>For any comment or question concerning this website, the indicative, non-exhaustive list of CAHRAs relevant to Regulation 2017/821, and the methodology underpinning it please contact <a href='mailto:CAHRAslist@randeurope.org'>CAHRAslist@randeurope.org</a>.</p>
        <p>Please note that you can also consult our <a href="https://www.rand.org/randeurope/research/projects/identifying-conflict-affected-and-high-risk-areas-for-eu-importe.html#faqs" target={'_blank'} rel='noopener noreferrer'>Frequently Asked Questions</a> page, which may provide an immediate answer to your question.</p>
      </Page.Content>
    </Page>
  )
}

export default ContactUs
