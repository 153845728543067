import React, {useEffect, useState} from 'react'
import {Container,} from 'semantic-ui-react'
import {Link, useHistory} from 'react-router-dom'
import './style.scss'

function Navigation() {
  const [openMenu, setOpenMenu] = useState('')
  const history = useHistory()
  const {location} = history
  const {pathname} = location

  useEffect(() => {
    setOpenMenu('')
  }, [pathname])

  const handleOpenMenu = (menuName) => {
    if (menuName === openMenu) {
      setOpenMenu('')
    } else {
      setOpenMenu(menuName)
    }
  }

  const isOpen = (className) => {
    if (className === openMenu) {
      return 'open'
    }
    return ''
  }

  return (
    <Container id="navigation">
      <div
        className={(pathname === '/cahras' || pathname === '/cahras-map')
          ? `cahras nav-item active ${isOpen('cahras')}`
          : `cahras nav-item ${isOpen('cahras')}`
        }
        onClick={() => handleOpenMenu('cahras')}
      >
        CAHRAs
        <div className="nav-dropdown">
          <Link to="/">
            <div className='dropdown-item'>
              Global map of CAHRAs
            </div>
          </Link>
          <Link to="/cahras">
            <div className='dropdown-item'>
              List of CAHRAs
            </div>
          </Link>
        </div>
      </div>
      <div
        className={(pathname === '/methodology'
          || pathname === '/data-sources'
          || pathname === '/glossary'
        )
          ? `methodology nav-item active ${isOpen('methodology')}`
          : `methodology nav-item ${isOpen('methodology')}`
      }
        onClick={() => handleOpenMenu('methodology')}
      >
        METHODOLOGY
        <div className="nav-dropdown">
          <Link to="/methodology">
            <div className='dropdown-item'>
              Methodology overview
            </div>
          </Link>
          <Link to="/glossary">
            <div className='dropdown-item'>
              Glossary
            </div>
          </Link>
          <Link to="/data-sources">
            <div className='dropdown-item'>
              Databases and information sources
            </div>
          </Link>
        </div>
      </div>
      <div
        className={(pathname === '/about' || pathname === '/contact-us')
          ? `about nav-item active ${isOpen('about')}`
          : `about nav-item ${isOpen('about')}`
        }
        onClick={() => handleOpenMenu('about')}
      >
        ABOUT
        <div className="nav-dropdown">
          <Link to="/about">
            <div className='dropdown-item'>
              About the project
            </div>
          </Link>
          <Link to="/contact-us">
            <div className='dropdown-item'>
              Contact us
            </div>
          </Link>
          <Link to="/terms-of-use">
            <div className='dropdown-item'>
              Terms of Use
            </div>
          </Link>
          <Link to="/privacy-policy">
            <div className='dropdown-item'>
              Privacy Policy
            </div>
          </Link>
          <Link to="/cookie-policy">
            <div className='dropdown-item'>
              Cookie Policy
            </div>
          </Link>

        </div>
      </div>
    </Container>
  )
}

export default Navigation
