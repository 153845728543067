import React from 'react'
import Page from 'components/Page'

import './style.scss'


function Glossary() {
  return (
    <Page id="about">
      <Page.Header>GLOSSARY</Page.Header>
      <Page.Content>
        <Page.Section className="glossary">
        <p>This glossary provides an overview of the key concepts and definitions used on this website and throughout the project methodology. These definitions are outlined in <a href={'https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32017R0821'} target={'_blank'} rel='noopener noreferrer'>Regulation (EU) 2017/821</a> and in <a href={'https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32018H1149'} target={'_blank'} rel='noopener noreferrer'>Recommendation (EU) 2018/1149</a>.</p>
        <p><b>Conflict-affected and high-risk areas (CAHRAs)</b> are defined as areas in a state of armed conflict or fragile post-conflict. The definition also covers areas witnessing weak or non-existent governance and security, such as failed states, and widespread and systematic violations of international law, including human rights abuses.</p>
        <p><b>A state of armed conflict</b> is defined by the presence of armed conflict, widespread violence or other risks of harm to people as described under humanitarian law. Armed conflict may be international or non-international, may involve two or more states, or may consist of wars of liberation or insurgencies, etc. Situations of internal disturbances and tensions such as riots or isolated acts of violence are not considered to be armed conflict.</p>
        <p><b>Fragile post-conflict areas</b> are areas witnessing a cessation of active hostilities and that are in a state of fragility, which means that they have a weak capacity to carry out basic governance functions. For an area to be considered a fragile post-conflict area according to Recommendation 2018/1149, it is necessary to ascertain that there is either institution weakness or a lack of governance and widespread and systematic violations of international law and human rights abuses.</p>
        <p><b>Fragile areas or areas part of a failed state</b> are areas witnessing weak or non-existent governance and security. Failed states are one example of such areas, but these areas also include those witnessing extreme institutional weakness. For an area to be considered as witnessing weak or non-existent governance and security according to Recommendation 2018/1149, it is necessary to ascertain that there is either institutional weakness or a lack of governance and widespread and systematic violations of international law and human rights abuses.</p>
        <p><b>Failed states</b> are an illustration of areas witnessing weak or non-existent governance and security. This implies an implosion of the structure of power and authority, a collapse of law and order, and the absence of institutions capable of representing the state.</p>
        <p><b>Mineral supply chain</b> refers to the system of activities, organisations, actors, technology, information, resources and services involved in moving and processing minerals from the extraction site to their incorporation in the final product.</p>
        <p><b>Supply chain due diligence</b> refers to the obligations of European Union importers of tin, tantalum and tungsten, their ores, and gold in relation to their management systems, risk management, independent third-party audits and disclosure of information. The aim is to identify and address actual and potential risks linked to conflict-affected and high- risk areas to prevent or mitigate adverse impacts associated with the company’s sourcing activities.</p>
        </Page.Section>
      </Page.Content>
    </Page>
  )
}

export default Glossary
