import React from 'react'

function PageSection({children}) {
  return (
    <div className="page-section">
      {children}
    </div>
  )
}

export default PageSection