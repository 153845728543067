import React from 'react'
import { Container } from 'semantic-ui-react'
import PageContent from './PageContent'
import PageHeader from './PageHeader'
import PageSection from './PageSection'

import './style.scss'

function Page({id, children}) {
  return (
    <Container id={id} className="page">
      {children}
    </Container>
  )
}

Page.Content = PageContent
Page.Header = PageHeader
Page.Section = PageSection

export default Page