import React from 'react'
import { Container, Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import GlobalMap from 'components/Maps/main'
import './style.scss'

const Home = () => {
  return (
    <Container id="home">
      <div className="home-header">
        <Header>GLOBAL MAP</Header>
      </div>

      <GlobalMap />

      <div className="map-disclaimer">
        <p>The boundaries, names and designations on this website’s maps do not imply endorsement or acceptance by RAND Europe or the European Commission.</p>
      </div>

      <div className="home-content">
        <p>The current indicative, non-exhaustive list of conflict-affected and high-risk areas under Regulation (EU) 2017/821 can be visualised on the <Link to="/cahras">List of CAHRAs</Link> page, alongside further information and individual reports for each CAHRA.</p>
        <p>For further information about the terminology and the methodology used to develop and update this indicative, non-exhaustive list of conflict-affected and high-risk areas under Regulation (EU) 2017/821, please refer to the <Link to="/methodology">Methodology</Link> section of this website.</p>
        <p>Should you have any questions about the project or about the methodology used to develop the current indicative, non-exhaustive list of CAHRAs under Regulation (EU) 2017/821, please refer to our <a href="https://www.rand.org/randeurope/research/projects/identifying-conflict-affected-and-high-risk-areas-for-eu-importe.html#faqs" target={'_blank'} rel='noopener noreferrer'>Frequently Asked Questions</a> page.</p>
      </div>
    </Container>
  )
}

export default Home
