import React from 'react'
import { Container, Header, List } from 'semantic-ui-react'
import Page from 'components/Page'
import './style.scss'

function DatabasesAndInformationSources() {
  return (
    <Page id="data-sources">
      <Page.Header>DATABASES AND INFORMATION SOURCES</Page.Header>
      <Page.Content>

        <Page.Section className="resources">
          <Header as='h2'>Databases and sources leveraged in the methodology</Header>
          <p>Please see below a list of resources and datasets employed by the project methodology. Please refer to individual CAHRA reports for any additional specific source employed.</p>

          <Container className={'indented-list'}>
            <List relaxed>
              <List.Item>Amnesty International. Amnesty International website. Amnesty.org.uk. As of 12 October 2020: <a href={'https://www.amnesty.org.uk'} target={'_blank'} rel='noopener noreferrer'>https://www.amnesty.org.uk</a></List.Item>
              <List.Item>Armed Conflict Location & Event Data Project (ACLED). As of 12 October 2020: <a href={'https://www.acleddata.com'} target={'_blank'} rel='noopener noreferrer'>https://www.acleddata.com</a></List.Item>
              <List.Item>British Geological Survey (BGS). 2020. World Mineral Statistics. Contributed by permission of the British Geological Survey.</List.Item>
              <List.Item>Bureau of International Labor Affairs (ILAB). ILAB website and reports. As of 12 October 2020: <a href={'https://www.dol.gov/agencies/ilab/our-work/child-forced-labor-trafficking'} target={'_blank'} rel='noopener noreferrer'>https://www.dol.gov/agencies/ilab/our-work/child-forced-labor-trafficking</a></List.Item>
              <List.Item>Ciuta, Theodor and Constantin Ciupagea. 2019. ‘The Raw Materials Supply Chain Viewer.’ Publications Office of the European Union. As of 12 October 2020: <a href={'https://ec.europa.eu/jrc/en/publication/raw-materials-supply-chain-viewer'} target={'_blank'} rel='noopener noreferrer'>https://ec.europa.eu/jrc/en/publication/raw-materials-supply-chain-viewer</a></List.Item>
              <List.Item>Crisis Group. 2020. ‘Crisis Watch. Tracking conflict worldwide.’ Crisisgroup.org. As of 12 October 2020: <a href={'https://www.crisisgroup.org/crisiswatch'} target={'_blank'} rel='noopener noreferrer'>https://www.crisisgroup.org/crisiswatch</a></List.Item>
              <List.Item>Database of Global Administrative Areas (GADM). 2018. GADM maps and data. As of 12 October 2020: <a href={'https://gadm.org'} target={'_blank'} rel='noopener noreferrer'>https://gadm.org</a></List.Item>
              <List.Item>DELVE. 2020. DELVE website. Delvedatabase.org. As of 12 October 2020: <a href={'https://delvedatabase.org/about'} target={'_blank'} rel='noopener noreferrer'>https://delvedatabase.org/about</a></List.Item>
              <List.Item>Department of Economic and Social Affairs, United Nations (DESA/UNSD). 2020. United Nations Comtrade database. As of 12 October 2020: <a href={'https://comtrade.un.org'} target={'_blank'} rel='noopener noreferrer'>https://comtrade.un.org</a></List.Item>
              <List.Item>Global Witness. Global Witness website. Globalwitness.org. As of 12 October 2020: <a href={'https://www.globalwitness.org/en/'} target={'_blank'} rel='noopener noreferrer'>https://www.globalwitness.org/en/</a></List.Item>
              <List.Item>Heidelberg Institute for International Conflict Research. 2020. Conflict Barometer 2019. As of 12 October 2020: <a href={'https://hiik.de/conflict-barometer/current-version/?lang=en'} target={'_blank'} rel='noopener noreferrer'>https://hiik.de/conflict-barometer/current-version/?lang=en</a></List.Item>
              <List.Item>Human Rights Watch (HRW). Human Rights Watch website. Hrw.org. As of 12 October 2020: <a href={'https://www.hrw.org/'} target={'_blank'} rel='noopener noreferrer'>https://www.hrw.org/</a></List.Item>
              <List.Item>International Peace Information Service (IPIS). IPIS website. Ipisresearch.be. As of 12 October 2020: <a href={'https://ipisresearch.be/'} target={'_blank'} rel='noopener noreferrer'>https://ipisresearch.be/</a></List.Item>
              <List.Item>Kaufmann, Daniel and Aart Kraay. 2019. Worldwide Governance Indicators. As of 12 October 2020: <a href={'https://info.worldbank.org/governance/wgi/'} target={'_blank'} rel='noopener noreferrer'>https://info.worldbank.org/governance/wgi/</a></List.Item>
              <List.Item>Mines and Communities (MAC). MAC website and reports. Minesandcommunities.org. As of 12 October 2020: <a href={'http://www.minesandcommunities.org'} target={'_blank'} rel='noopener noreferrer'>http://www.minesandcommunities.org</a></List.Item>
              <List.Item>Natural Resource Governance Institute (NRGI). 2017. Resource Governance Index. As of 12 October 2020: <a href={'https://resourcegovernance.org'} target={'_blank'} rel='noopener noreferrer'>https://resourcegovernance.org</a></List.Item>
              <List.Item>Office of the United Nations High Commissioner for Human Rights. OHCHR homepage. Ohchr.org. As of 12 October 2020: <a href={'https://www.ohchr.org/EN/pages/home.aspx'} target={'_blank'} rel='noopener noreferrer'>https://www.ohchr.org/EN/pages/home.aspx</a></List.Item>
              <List.Item>Opaxe. 2020. Quarterly datasets. Courtesy of Opaxe</List.Item>
              <List.Item>Geneva Academy Rule of Law in Armed Conflicts (RULAC). 2019. ‘RULAC: Rule of Law in Armed Conflicts.’ RULAC.org. As of 12 October 2020: <a href={'http://www.rulac.org'} target={'_blank'} rel='noopener noreferrer'>http://www.rulac.org</a></List.Item>
              <List.Item>Temper, Leah, Daniela del Bene and Joan Martinez-Alier. 2015. ‘Mapping the frontiers and front lines of global environmental justice: the EJAtlas.’ Journal of Political Ecology 22: 255-278.</List.Item>
              <List.Item>The Fund for Peace. 2020. Fragile States Index. As of 12 October 2020: <a href={'https://fragilestatesindex.org'} target={'_blank'} rel='noopener noreferrer'>https://fragilestatesindex.org</a></List.Item>
              <List.Item>Uppsala Conflict Data Program (UCDP) dataset 19.1 Sundberg, Ralph, and Erik Melander, 2013, ‘Introducing the UCDP Georeferenced Event Dataset.’ Journal of Peace Research 50(4): 523-532.</List.Item>
              <List.Item>United Nations (UN). ‘Geospatial Information Section.’ Operations Support Division, Office of Information and Communications Technology. Un.org. As of 12 October 2020: <a href={'https://www.un.org/Depts/Cartographic/english/htmain.htm'} target={'_blank'} rel='noopener noreferrer'>https://www.un.org/Depts/Cartographic/english/htmain.htm</a></List.Item>
              <List.Item>Vásquez, Ian and Tanja Porčnik. 2019. The Human Freedom Index 2019. A global measurement of personal, civil, and economic freedom. Cato Institute, the Fraser Institute and the Friedrich Naumann</List.Item> <List.Item>Foundation for Freedom. As of 12 October 2020: <a href={'https://www.cato.org/sites/cato.org/files/human-freedom-index-files/cato-human-freedom-index-update-3.pdf'} target={'_blank'} rel='noopener noreferrer'>https://www.cato.org/sites/cato.org/files/human-freedom-index-files/cato-human-freedom-index-update-3.pdf</a></List.Item>
              <List.Item>World Bank. 2020. FY2021 List of Fragile and Conflict-affected Situations. As of 12 October 2020: <a href={'http://pubdocs.worldbank.org/en/888211594267968803/FCSList-FY21.pdf'} target={'_blank'} rel='noopener noreferrer'>http://pubdocs.worldbank.org/en/888211594267968803/FCSList-FY21.pdf</a></List.Item>
            </List>
          </Container>
        </Page.Section>

        <Page.Section className="databases">
          <Header as='h2'>Resources available to support due diligence efforts</Header>
          <p>In addition to databases leveraged for the project methodology, additional sources are available for companies to support their due diligence and research efforts. Please find below an indicative and non-exhaustive list of resources that may be of interest:</p>

          <Container className={'indented-list'}>
            <List relaxed>
              <List.Item>European Partnership for Responsible Minerals. As of 12 October 2020: <a href={'https://europeanpartnership-responsibleminerals.eu'} target={'_blank'} rel='noopener noreferrer'>https://europeanpartnership-responsibleminerals.eu</a></List.Item>
              <List.Item>OECD Due Diligence Guidance for Responsible Supply Chains of Minerals from Conflict-Affected and High-Risk Areas. As of 12 October 2020: <a href={'http://www.oecd.org/corporate/mne/mining.htm'} target={'_blank'} rel='noopener noreferrer'>http://www.oecd.org/corporate/mne/mining.htm</a></List.Item>
              <List.Item>INFORM index. As of 12 October 2020: <a href={'https://drmkc.jrc.ec.europa.eu/inform-index'} target={'_blank'} rel='noopener noreferrer'>https://drmkc.jrc.ec.europa.eu/inform-index</a></List.Item>
              <List.Item>United Nations Development Programme. International Human Development Indicators: Country Profiles. As of 12 October 2020: <a href={'http://hdr.undp.org/en/countries'} target={'_blank'} rel='noopener noreferrer'>http://hdr.undp.org/en/countries</a></List.Item>
              <List.Item>United Nations Security Council Resolutions (UNSC). As of 12 October 2020: <a href={'https://www.un.org/securitycouncil/content/resolutions-0'} target={'_blank'} rel='noopener noreferrer'>https://www.un.org/securitycouncil/content/resolutions-0</a></List.Item>
            </List>
          </Container>
        </Page.Section>

      </Page.Content>
    </Page>
  )
}

export default DatabasesAndInformationSources
