import React from 'react'
import ScrollToTop from 'components/ScrollToTop'

// External Stuff
import About from 'pages/About'
import CAHRAs from 'pages/CAHRAs'
import DatabasesAndInformationSources from 'pages/DatabasesAndInformationSources'
import Glossary from 'pages/Glossary'
import Home from 'pages/Home'
import Methodology from 'pages/Methodology'
import CookiePolicy from 'pages/CookiePolicy'
import PrivacyPolicy from 'pages/PrivacyPolicy'
import TermsOfUse from 'pages/TermsOfUse'

import Navigation from 'components/Navigation'
import Oops from 'pages/Oops'
import AppHeader from 'components/Header'
import Footer from 'components/Footer'

import {useCookies} from 'react-cookie'

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom'
import 'app.scss'
import Disclaimer from 'components/Disclaimer'
import ContactUs from 'pages/ContactUs'

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <RouteWrapper
          component={Home}
          exact
          layout={AppLayout}
          path='/'
        />
        <RouteWrapper
          component={About}
          exact
          layout={AppLayout}
          path='/about'
        />
        <RouteWrapper
          component={Glossary}
          exact
          layout={AppLayout}
          path='/glossary'
        />
        <RouteWrapper
          component={CAHRAs}
          exact
          layout={AppLayout}
          path='/cahras'
        />
        <RouteWrapper
          component={Methodology}
          exact
          layout={AppLayout}
          path='/methodology'
        />
        <RouteWrapper
          component={DatabasesAndInformationSources}
          exact
          layout={AppLayout}
          path='/data-sources'
        />
        <RouteWrapper
          component={ContactUs}
          exact
          layout={AppLayout}
          path='/contact-us'
        />
        <RouteWrapper
          component={CookiePolicy}
          exact
          layout={AppLayout}
          path='/cookie-policy'
        />
        <RouteWrapper
          component={PrivacyPolicy}
          exact
          layout={AppLayout}
          path='/privacy-policy'
        />
        <RouteWrapper
          component={TermsOfUse}
          exact
          layout={AppLayout}
          path='/terms-of-use'
        />
        <RouteWrapper path="/404" component={Oops} layout={AppLayout} />
        <Redirect to="/404" />
      </Switch>
    </Router>
  )
}

function RouteWrapper({
  component: Component,
  layout: Layout,
  ...rest
}) {
  const [cookies] = useCookies(['cahras-disclaimer'])

  if (cookies['cahras-disclaimer'] === undefined) {
    return (
      <Route {...rest} render={(props) =>(
        <div id="app">
          <AppHeader />
          <Disclaimer />
          <Footer />
        </div>
      )} />
    )
  }

  return (
    <Route {...rest} render={(props) =>(
      <Layout {...props}>
        <Component {...props} />
      </Layout>
    )} />
  )
}

function AppLayout({ children }) {
  return (
    <div id="app">
      <AppHeader />
      <Navigation />
      <div className="content">
        {children}
      </div>
      <Footer />
    </div>
  )
}

export default App
