import React from 'react'
import {Grid, Header, Image} from 'semantic-ui-react'
import Page from 'components/Page'

function ProducingList() {
  return (
    <Page.Section>
      <Header as="h3">Producing and updating an indicative, non-exhaustive list of CAHRAs under Regulation 2017/821</Header>

      <div className="translating-requirements">
        <p>In order to translate project requirements into an operational approach, the research team developed a methodology based on the following design principles:</p>

        <Grid columns={3} stackable>
          <Grid.Column textAlign="center" className="grid-item">
            <Image centered src="assets/images/transparency.png" size="small" />
            <p><strong>Transparency and replicability</strong> – the research team endeavoured to design a methodology that is transparent and replicable by third parties, but that does not compromise on the analytical complexity required for this task.</p>
          </Grid.Column>
          <Grid.Column textAlign="center" className="grid-item">
            <Image centered src="assets/images/systematicity-objectivity.png" size="small" />
            <p><strong>Systematicity and objectiveness</strong> – the methodology is based on a systematic approach that is applicable on a global scale and that leverages a consistent set of data sources.</p>
          </Grid.Column>
          <Grid.Column textAlign="center" className="grid-item">
            <Image centered src="assets/images/data-triangulation.png" size="small" />
            <p><strong>Data triangulation</strong> – each factor and driver employed to determine whether a region should be considered a CAHRA is assessed from multiple data sources and perspectives to guarantee maximum reliability of the data employed and of the resulting analysis.</p>
          </Grid.Column>
          <Grid.Column textAlign="center" className="grid-item">
            <Image centered src="assets/images/data-optimisation.png" size="small" />
            <p><strong>Data optimisation</strong> – available project resources are used in an effective and economic manner, reducing unnecessary duplication of data.</p>
          </Grid.Column>
          <Grid.Column textAlign="center" className="grid-item">
            <Image centered src="assets/images/scanning.png" size="small" />
            <p><strong>Scanning the scanners</strong> – the methodology is designed to benefit as much as possible from work and analysis already being conducted by reputable institutions and organisations identified in Recommendation 2018/1149 and highlighted by the experts and stakeholders consulted.</p>
          </Grid.Column>
          <Grid.Column textAlign="center" className="grid-item">
            <Image centered src="assets/images/flexibility.png" size="small" />
            <p><strong>Flexibility and adaptability</strong> – the methodology is designed to retain a significant degree of flexibility and adaptability in order to ensure that it can adapt over time in case of new developments, changes and adjustments to relevant legislation, as well as in the case of new data sources becoming available.</p>
          </Grid.Column>
        </Grid>
      </div>
    </Page.Section>
  )
}

export default ProducingList
