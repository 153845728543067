import React from 'react'
import {Header} from 'semantic-ui-react'
import Page from 'components/Page'

function MethodologyOverview() {
  return (
    <React.Fragment>
      <Header as='h2'>Methodology overview</Header>
      <Page.Section>
        <Header as='h3'>Developing the methodology</Header>
        <p>RAND Europe has designed a methodology to produce and regularly update an indicative, non-exhaustive list of CAHRAs relevant to Regulation 2017/821. Based on an analysis of key legislation, project documentation, and stakeholder and expert input, the research team designed this methodology according to the requirement that it should:</p>

        <ul>
          <li>Be fully in line with the definition of CAHRAs as set out in Regulation 2017/821.</li>
          <li>Be consistent and coherent with Commission Recommendation 2018/1149.</li>
          <li>Be able to accommodate a global scope of analysis.</li>
          <li>Be able to identify CAHRAs on the basis of subnational assessments.</li>
          <li>Include in its assessment not only regions associated with production and extraction, but also those associated with other parts of the supply and value chain.</li>
          <li>Include rather than exclude a CAHRA when faced with questionable, incomplete or contradictory information.</li>
          <li>Be transparent and replicable.</li>
          <li>Build on existing resources and methods developed by recognised institutions and organisations.</li>
        </ul>
        <p>The development of the methodology relied on the following activities:</p>
      </Page.Section>
    </React.Fragment>
  )
}

export default MethodologyOverview
