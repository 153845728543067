import React from 'react'

function PageContent({children}) {
  return (
    <div className="page-content">
      {children}
    </div>
  )
}

export default PageContent