import React from 'react'
import axios from 'axios'
import DeckGL from 'deck.gl'
import { MVTLayer } from '@deck.gl/geo-layers'
import { StaticMap } from 'react-map-gl'
import { Dimmer, Loader } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'

import config from 'config'
import './style.scss'

const { apiUrl } = config
const MAPBOX_API_ACCESS_TOKEN = get(config, 'map.MAPBOX_API_ACCESS_TOKEN')
const MAPBOX_TILESET = get(config, 'map.GADM36_0_TILESET') // Countries

const INITIAL_VIEW_STATE = {
  longitude: 15.0,
  latitude: 10.0,
  zoom: 1,
  pitch: 0,
  bearing: 0,
}

class GlobalMap extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      viewState: INITIAL_VIEW_STATE,
      cahraCountryCodes: [],
      layers: [],
    }

    this.getSubdivisionLayer = this.getSubdivisionLayer.bind(this)
    this.toggleLoading = this.toggleLoading.bind(this)
  }

  componentDidMount() {
    axios.get(`${apiUrl}/api/v1/cahras`)
      .then(results => {
        console.log('CAHRA RESULTS:')
        console.log(results)
        this.setState({
          cahraCountryCodes: results.data.map(country => country.country_code),
        })
      })
      .catch(err => {
        console.error('Could not load CAHRA reports: ' + err)
      })
  }

  componentDidUpdate(_, prevState) {
    if (this.state.cahraCountryCodes !== prevState.cahraCountryCodes) {
      this.setState({
        layers: [this.getSubdivisionLayer()]
      })
    }
  }

  getSubdivisionLayer() {
    const { cahraCountryCodes } = this.state
    return new MVTLayer({
      data: `https://api.mapbox.com/v4/${MAPBOX_TILESET}/{z}/{x}/{y}.vector.pbf?access_token=${MAPBOX_API_ACCESS_TOKEN}`,
      minZoom: 0,
      maxZoom: 10,
      getFillColor: (e) => {
        const code = get(e, 'properties.GID_0')
        if (cahraCountryCodes.includes(code)) {
          return [228, 176, 0, 100]
        }
        return [0, 0, 0, 0] // transparent
      },
      getLineColor: [43, 48, 90, 25],
      autoHighlight: true,
      highlightColor: [45, 178, 154, 100],
      pickable: true,
      getLineWidth: 2,
      lineWidthMinPixels: 1,
      onClick: (e) => {
        const code = get(e, 'object.properties.GID_0')
        if(cahraCountryCodes.includes(code)) {
          this.props.history.push(`cahras#${code}`)
        }
      },
      onViewportLoad: this.toggleLoading,
    })
  }

  toggleLoading() {
    this.setState({loading: false})
  }

  render() {
    return (
      <div className='map-container'>
        <Dimmer active={this.state.loading}>
          <Loader>Loading map...</Loader>
        </Dimmer>
        <DeckGL
          id='global-map'
          initialViewState={INITIAL_VIEW_STATE}
          controller={false} // Set to true for pan/zoom
          layers={this.state.layers}
        >
          {/* If you ever need to access Deck attributes...
            ({x, y, width, height, viewState, viewport}) => {
            console.log('INSIDE DECK.GL')
            console.log(viewport.getBounds())
          }*/}

          <StaticMap
            mapboxApiAccessToken={MAPBOX_API_ACCESS_TOKEN} />
        </DeckGL>
      </div>
    )
  }

}

export default withRouter(GlobalMap)
