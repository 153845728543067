import React from 'react'
import {Header} from 'semantic-ui-react'
import Page from 'components/Page'
import { Link } from 'react-router-dom'
import MethodologyOverview from './MethodologyOverview'
import ActivitiesGrid from './ActivitiesGrid'

import './styles.scss'
import ProducingList from './ProducingList'

function Methodology() {
  return (
    <Page id="methodology">
      <Page.Header>METHODOLOGY OVERVIEW</Page.Header>
      <Page.Content>

        <Header as='h2'>About CAHRAs</Header>
        <Page.Section>
          <Header as='h3'>Policy and project context</Header>
          <p>Natural mineral resources can be a driver of development and can generate income and economic growth for local economies; however, they can also contribute to the outbreak of violent conflicts and the exploitation of local communities.</p>
          <p>The European Union (EU) is committed to ensuring that its imports of minerals and metals are sourced responsibly and align with European policies on conflict prevention and development. In light of this commitment, Regulation (EU) 2017/821 (‘the Regulation’) requires EU importers of tin, tantalum, tungsten and gold (and the minerals containing them) to carry out supply chain due diligence based on the five-step approach established by the Organisation for Economic Co-operation and Development (OECD).</p>
          <p>The European Commission Directorate General for Trade (DG TRADE) has contracted RAND Europe to conduct a project for the provision of an indicative, non-exhaustive and regularly updated list of conflict-affected and high-risk areas (as set out in Article 14(2) of the Regulation). The objective of this project is to develop and maintain updated such a list of CAHRAs to facilitate the calibration of due diligence efforts made by EU importers of the relevant metals and minerals.</p>
        </Page.Section>

        <MethodologyOverview />
        <ActivitiesGrid />
        <ProducingList />

        <p>For further information, please refer to the methodology report <a href={'https://www.rand.org/pubs/research_reports/RRA158-1-v2.html'} target={'_blank'} rel='noopener noreferrer'>available for download on RAND Europe’s website</a> which provides a detailed overview of the methodological approach.</p>
        <p>This methodology is reviewed on a periodic basis. If you would like to provide feedback or suggestions for improvement, please use our <Link to={'/contact-us'}>contact form</Link>. Your submission will be reviewed in the next revision.</p>
        <p>
        Should you have any questions about the project or about the methodology used to develop the indicative, non-exhaustive list of CAHRAs under Regulation (EU) 2017/821, please refer to our <a href="https://www.rand.org/randeurope/research/projects/identifying-conflict-affected-and-high-risk-areas-for-eu-importe.html#faqs" target={'_blank'} rel='noopener noreferrer'>Frequently Asked Questions</a> page.
        </p>
      </Page.Content>
    </Page>
  )
}

export default Methodology
