import React from 'react'
import {useCookies} from 'react-cookie'
import {
  Button,
  Container,
  Grid,
  Header,
  Sidebar,
} from 'semantic-ui-react'

import './style.scss'

function CookieConsentBanner() {
  const [visible, setVisible] = React.useState(true)
  const [cookies, setCookie] = useCookies(['cookie-consent'])

  if (cookies['cookie-consent']) {
    return null
  }

  const handleAcceptCookieConsent = () => {
    setCookie('cookie-consent', true, {maxAge: 3600*24}) // 24hr
    setVisible(false)
  }

  return (
    <Sidebar
      id='cookie-consent-banner'
      direction='bottom'
      animation='overlay'
      icon='labeled'
      inverted
      vertical
      visible={visible}
      width='thin'
    >
      <Container className='content'>
        <Grid columns='equal'>
          <Grid.Row>
            <Grid.Column width={12}>
              <Header as={'h4'}>How do we use cookies?</Header>
              <p>
                RAND Corporation uses cookies to recognise your repeated visits and record your agreement to the <a href={'/terms-of-use'}>Terms of Use</a> for this website. By clicking accept here you accept this use of such cookies.
              </p>
            </Grid.Column>
            <Grid.Column verticalAlign='middle' textAlign='right'>
              <Button
                className='button'
                onClick={()=>handleAcceptCookieConsent()}
                color='blue'
                content='I ACCEPT' />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Sidebar>
  )
}

export default CookieConsentBanner
