const dev = {
  apiUrl: 'http://localhost:5000',
  map: {
    MAPBOX_API_ACCESS_TOKEN: 'pk.eyJ1IjoicmFuZG1hcGJveCIsImEiOiJja2h2OW52cWcxMWNpMnluM2RxNDhieHd3In0.oEnxwXChli24jEQ1n1RMfQ',
    MAIN_STYLE: 'mapbox://styles/randmapbox/cki579ded6c7c19o6pxrt3k23',
    GADM36_0_TILESET: 'randmapbox.8fwfzjdk', // Countries
    GADM36_1_TILESET: 'randmapbox.b0mhtlfb', // Subdivisions
  },
  debug: true,
}

const prod = {
  apiUrl: 'https://cahra-api.cahraslist.net',
  map: {
    MAPBOX_API_ACCESS_TOKEN: 'pk.eyJ1IjoicmFuZG1hcGJveCIsImEiOiJja2h2OTlucXIxZm45MnducG5zNGt0b3RqIn0.Uf_nP9-uda9Wvwu7jtpHPg',
    MAIN_STYLE: 'mapbox://styles/randmapbox/cki579ded6c7c19o6pxrt3k23',
    GADM36_0_TILESET: 'randmapbox.8fwfzjdk', // Countries
    GADM36_1_TILESET: 'randmapbox.b0mhtlfb', // Subdivisions
  },
  debug: false,
}

const config = process.env.REACT_APP_STAGE === 'production'
  ? prod
  : dev

export default config;
