import React from 'react'
import { Header } from 'semantic-ui-react'

function PageHeader({children}) {
  return (
    <div className="page-header">
      <Header>{children}</Header>
    </div>
  )
}

export default PageHeader