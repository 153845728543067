import React from 'react'
import { Header } from 'semantic-ui-react'

import Page from 'components/Page'
import './style.scss'

function PrivacyPolicy() {
  return (
    <Page id="privacy-policy">
      <Page.Content>

      <Page.Header>PRIVACY POLICY</Page.Header>
        <p>
          This privacy policy sets out how RAND Europe uses and protects any information that you give when you use this website.
        </p>
        <p>
          RAND Europe is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, you can be assured that it will only be used in accordance with this privacy statement.
        </p>
        <p>
          RAND Europe may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes. This policy is effective from 1 November 2020.
        </p>

      <Page.Section>
        <Header as="h2">WHAT PERSONAL DATA WE COLLECT WHY WE COLLECT IT</Header>
        <p>
          We will collect your basic personal data (email and any other personal data you provide) from emails you send to the website email address in order to correspond with you now and in the future and organise your correspondence. We note:
        </p>
        <ul>
          <li>
            The data will be securely stored digitally. Access to the data is controlled on a ‘need to know’ basis and only available to the project team.
          </li>
          <li>
            All data provided will be processed in accordance with standards of the EU’s General Data Protection Regulation (GDPR).
          </li>
          <li>
            All personally identifiable data collected as part of this project will be <b>deleted one year after the end of the project</b>.
          </li>
        </ul>

      </Page.Section>

      <Page.Section>
        <Header as="h2">LEGAL BASIS</Header>
        <p>
          The legal basis that we collect your data is our legitimate interest. We believe that in providing your details you do so with the expectation that we correspond with you and in doing so this does not affect your rights and freedoms.
        </p>
      </Page.Section>

      <Page.Section>
        <Header as="h2">WHO WE SHARE YOUR DATA WITH</Header>
        <p>
          We will not share any personal data with anyone.
        </p>
      </Page.Section>

      <Page.Section>
        <Header as="h2">HOW LONG WE RETAIN YOUR DATA</Header>
        <p>
          All personally identifiable data collected as part of this project will be deleted one year after the end of the project or upon request.
        </p>
      </Page.Section>

      <Page.Section>
        <Header as="h2">WHAT RIGHTS YOU HAVE OVER YOUR DATA</Header>
        <ul>
          <li>
            Right to access: You have the right to access most of the data by contacting <a href='mailto:redpo@rand.org'>redpo@rand.org</a> (RAND Europe).
          </li>
          <li>
            Right to rectify: You can also rectify or update any information where you believe it is incorrect by contacting <a href='mailto:redpo@rand.org'>redpo@rand.org</a> (RAND Europe).
          </li>
          <li>
            Right to erase: You may also erase any data by contacting <a href='mailto:redpo@rand.org'>redpo@rand.org</a> (RAND Europe).
          </li>
          <li>
            <b>Right to contact the data authority</b>: You have the right to contact the Information Commissioner’s Office in the UK if you have any concerns about the processing of your data by RAND Europe.
          </li>
        </ul>

      </Page.Section>

      <Page.Section>
        <Header as="h2">CONTACT INFORMATION</Header>
        <p>
          You can contact us about your privacy to email: <a href='mailto:redpo@rand.org'>redpo@rand.org</a>
        </p>
      </Page.Section>

      </Page.Content>
    </Page>
  )
}

export default PrivacyPolicy
