import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Container } from 'semantic-ui-react'
import './style.scss'

function Oops() {
  return (
    <div id="oops" className="page">
      <div className="page-header" style={{textAlign: 'center'}}>
        <h1 style={{fontSize: '4rem'}}>
          404
          <br/>
          <span style={{fontSize: '2rem'}}>
            PAGE NOT FOUND
          </span>
        </h1>
      </div>

      <Container
        className="page-content"
        textAlign="center"
        style={{fontSize: '1.5rem'}}
      >
        <p>
          The page that you are looking for might have been removed, had its name changed or is temporarily unavailable.
        </p>
        <Button
          size="huge"
          className="homepage-btn"
          as={Link}
          to="/"
        >
          HOMEPAGE
        </Button>
      </Container>
    </div>
  )
}

export default Oops