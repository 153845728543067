import React from 'react'
import {Grid, Image} from 'semantic-ui-react'
import Page from 'components/Page'
import { Link } from 'react-router-dom'

function ActivitiesGrid() {
  return (
    <Page.Section>
      <Grid columns={3} stackable>
        <Grid.Column textAlign="center" className="grid-item">
          <Image centered src="assets/images/factors.png" size="small" />
          <p>Identification of factors, drivers and metrics pertaining to conflict, governance, human rights and commodities relevant to Regulation 2017/821.</p>
        </Grid.Column>
        <Grid.Column textAlign="center" className="grid-item">
          <Image centered src="assets/images/data-sources.png" size="small" />
          <p>Identification of data sources to collect and monitor data required to produce a robust assessment of CAHRAs under Regulation 2017/821. For a complete overview of all data sources leveraged to produce this list, please refer to the <Link to="/data-sources">Databases and Information Sources</Link> page.</p>
        </Grid.Column>
        <Grid.Column textAlign="center" className="grid-item">
          <Image centered src="assets/images/development.png" size="small" />
          <p>Development of a methodology workflow and analytical approach to produce an indicative, non-exhaustive and regularly updated list of CAHRAs under Regulation 2017/821.</p>
        </Grid.Column>
      </Grid>

      <p>To carry out these activities, the team leveraged different research methods:</p>

      <Grid columns={2} stackable>
        <Grid.Column textAlign="center" className="grid-item">
          <Image centered src="assets/images/literature-review.png" size="small" />
          <p><strong>Literature review</strong> – This entailed a desk-based literature review of the following: academic and grey literature relevant to the conceptualisation of CAHRAs and to issues of governance and human rights, databases and data repositories listed in Recommendation 2018/1149, and resources highlighted by the European Commission and other stakeholders and experts consulted during the study.</p>
        </Grid.Column>
        <Grid.Column textAlign="center" className="grid-item">
          <Image centered src="assets/images/consultation.png" size="small" />
          <p><strong>Stakeholder and expert consultations</strong> – During the development of the methodology, the research team conducted interviews with stakeholders and experts to validate and refine the approach. Experts and stakeholders also provided feedback on the final methodology report.</p>
        </Grid.Column>
      </Grid>
    </Page.Section>
  )
}

export default ActivitiesGrid
