import React from 'react'
import Page from 'components/Page'

import './style.scss'
import { Header } from 'semantic-ui-react'


function About() {
  return (
    <Page id="about">
      <Page.Header>ABOUT THIS PROJECT</Page.Header>
      <Page.Content>
        <Page.Section className="about-this-project">
          <Header as="h2">About this project</Header>
          <p>Natural mineral resources can be a driver of development and can generate income and economic growth for local economies; however, they can also contribute to the outbreak of violent conflicts and the exploitation of local communities.</p>
          <p>The European Union (EU) is committed to ensuring that its imports of minerals and metals are sourced responsibly and align with European policies on conflict prevention and development. In light of this commitment, Regulation (EU) 2017/821 (‘the Regulation’) requires EU importers of tin, tantalum, tungsten and gold (and the minerals containing them) to carry out supply chain due diligence based on the five-step approach established by the Organisation for Economic Co-operation and Development (OECD).</p>
          <p>The European Commission Directorate General for Trade (DG TRADE) has contracted RAND Europe to conduct a project for the provision of an indicative, non-exhaustive and regularly updated list of conflict-affected and high-risk areas (as set out in Article 14(2) of the Regulation). The objective of this project is to develop and maintain updated such a list to facilitate the calibration of due diligence efforts made by EU importers of the relevant metals and minerals.</p>
          <p>Should you have any questions about the project, please refer to our <a href="https://www.rand.org/randeurope/research/projects/identifying-conflict-affected-and-high-risk-areas-for-eu-importe.html#faqs" target={'_blank'} rel='noopener noreferrer'>Frequently Asked Questions</a> page.</p>
        </Page.Section>
        <Page.Section className="about-rand-europe">
          <Header as='h2'>About RAND Europe</Header>
          <p>RAND Europe is a not-for-profit policy research organisation that helps to improve policy and decision making through objective research and analysis. RAND Europe’s clients include European governments, institutions, non-governmental organisations and others with a need for rigorous, independent and multidisciplinary analysis.</p>
        </Page.Section>
      </Page.Content>
    </Page>
  )
}

export default About
