import React from 'react'
import { Header } from 'semantic-ui-react'

import Page from 'components/Page'
import './style.scss'

function TermsOfUse() {
  return (
    <Page id="terms-of-use">
      <Page.Header>WEBSITE TERMS AND CONDITIONS</Page.Header>
      <Page.Content>
        <Page.Section className="preamble">
          <p>
            <b>Use of the website is subject to the following terms and conditions:</b>
          </p>
          <Header as="h2">PREAMBLE</Header>
          <p>
            This website and its underlying project are to be understood in the broader context of the European Commission's <a href={'https://ec.europa.eu/trade/whatwedo/work/index_en.htm'} target={'_blank'} rel='noopener noreferrer'>work on trade in general</a> and <a href={'http://ec.europa.eu/trade/policy/in-focus/conflict-minerals-regulation/'} target={'_blank'} rel='noopener noreferrer'>responsible sourcing of minerals in particular</a>. The objective of this project and of the CAHRAs list presented on this website is to facilitate due diligence efforts of European Union importers of minerals and metals falling under Regulation (EU) 2017/821. Information available through this website should be seen as indicative, not exhaustive, and as constituting only a part of, and is not intended to substitute and cannot replace, the broader due diligence efforts required to be conducted by relevant organisations and companies. Therefore, no reliance can and should be placed on the use of this website in respect of contributing to or meeting a person, organisation or company’s legal obligations in relation to Regulation (EU) 2017/821.
          </p>
          <p>
            It must be noted that importers sourcing from areas which are not mentioned on the list presented on this website remain responsible for complying with the due diligence obligations under Regulation 2017/821. Neither RAND Europe nor the European Commission (as contracting authority) assume any responsibility or liability in relation to the contents of this website nor the use of those contents. Equally, the inclusion of an area in the website’s CAHRAs list does not prohibit, imply, or suggest that business activities should not be conducted in this area, assuming that due diligence obligations are properly fulfilled.
          </p>
          <p>
            It must be noted that importers sourcing from areas which are not mentioned on the list included on this website remain responsible for complying with the due diligence obligations under Regulation 2017/821. Neither RAND Europe nor the European Commission (as contracting authority) assume any liability in relation to the contents of this website nor the use of those contents. Equally, the inclusion of an area in the website’s CAHRAs list does not prohibit, imply, or suggest that business activities should not be conducted in this area, assuming that due diligence obligations are properly fulfilled.
          </p>
        </Page.Section>

        <Page.Section className="copyright">
          <Header as="h2">COPYRIGHT</Header>
          <p>
            All information on and distributed via this site is the copyright of the European Union, 2020 unless otherwise stated. Reproduction of European Union content is authorised, provided the source is acknowledged. All other content on or distributed via this website may only be used for commercial purposes with the written permission of the European Commission, RAND Europe or the applicable content owner.
          </p>
        </Page.Section>

        <Page.Section className="terms-of-use">
          <Header as="h2">TERMS OF USE</Header>
          <p>
            <b><u>Use</u></b>
          </p>

          <p>
            You may consult the website, download CAHRAs reports for information purposes only and subject to the limitations otherwise set out in these terms. The European Union and RAND Europe’s status (and that of any identified contributors) as the authors of content on our site must always be acknowledged.
          </p>
          <p>
            Where content is identified as owned by contributors other than the European Union you must not reproduce, copy or otherwise exploit any part of the content on our site for commercial purposes without obtaining a licence to do so from us RAND Europe or the European Commission.
          </p>
          <p>
            The information and views set out in this website are those of the authors and do not necessarily reflect the official opinion of the European Commission. Neither RAND Europe (EU) nor the Commission guarantee the accuracy of the data included in this list. Neither the RAND Europe (EU), the Commission nor any person acting on its behalf may be held responsible for the use which may be made of the information contained therein. Specifically, the content on our site is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our site.
          </p>
          <p>
            Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that the content on our site is accurate, complete or up to date.
          </p>
          <p>
            This website and its reports are not official documents. Care has been taken to ensure the accuracy of the information contained within the website and the indicative, non-exhaustive list, but no responsibility can be accepted by the European Commission, RAND Europe, or the data providers for any inadvertent errors or omissions. The information in this website may not be used for resale (or in the provision of consultancy services), redistribution, building of databases, storage.
          </p>
          <p>
            The boundaries, names and designations on this website’s maps do not imply endorsement or acceptance by RAND Europe or the European Commission.
          </p>

          <p>
            <b><u>Liability</u></b>
          </p>

          <p>
            Our responsibility for loss or damage suffered by you whether you are a consumer or a business user is as follows. We do not in any way exclude or limit our liability to you where it would be unlawful to do so.
          </p>
          <p>
            If you are a business user:
          </p>
          <ul>
            <li>
              We exclude all implied conditions, warranties, representations or other terms that may apply to our site or any content on it.
            </li>
            <li>
              We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:
            </li>
              <ul>
                <li>
                  use of, or inability to use, our site; or
                </li>
                <li>
                  use of or reliance on any content displayed on our site.
                </li>
              </ul>
              <li>
                In particular, we will not be liable for:
              </li>
              <ul>
                <li>
                  loss of profits, sales, business, or revenue;
                </li>
                <li>
                  business interruption;
                </li>
                <li>
                  loss of anticipated savings;
                </li>
                <li>
                  loss of business opportunity, goodwill or reputation; or
                </li>
                <li>
                  any indirect or consequential loss or damage.
                </li>
              </ul>
          </ul>
          <p>
            If you are a consumer user, we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.
          </p>

          <p>
            <b><u>Viruses</u></b>
          </p>

          <p>
            We do not guarantee that our site will be secure or free from bugs or viruses.
          </p>

          <p>
            <b><u>Linking</u></b>
          </p>

          <p>
            You may link to our home page, provided you do so in a way that is fair and legal and does not damage RAND Europe or the European Commission’s reputation or take advantage of it.
          </p>
          <p>
            You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.
          </p>
          <p>
            You must not establish a link to our site in any website that is not owned by you.
          </p>
          <p>
            Our site must not be framed on any other site, nor may you create a link to any part of our site other than the home page.
          </p>
          <p>
            We reserve the right to withdraw linking permission without notice.
          </p>
          <p>
            If you wish to link to or make any use of content on our site other than that set out above, please contact <a href='mailto:CAHRAslist@rand.org'>CAHRAslist@rand.org</a>.
          </p>
          <p>
            Where our site contains links to other sites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them. We have no control over the contents of those sites or resources.
          </p>

          <p>
            <b><u>Law</u></b>
          </p>

          <p>
            Your use of this website and any dispute arising out of such use of the website is subject to the laws of European Union.
          </p>
        </Page.Section>

      </Page.Content>
    </Page>
  )
}

export default TermsOfUse
