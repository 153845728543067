import React, {useState} from 'react'
import {Button, Checkbox, Container} from 'semantic-ui-react'
import {useCookies} from 'react-cookie'

import './style.scss'

function Disclaimer() {
  const [acceptedDisclaimer, toggleAcceptedDisclaimer] = useState(false)
  const [, setCookie] = useCookies(['cahras-disclaimer'])

  const handleAcceptDisclaimer = () => {
    setCookie('cahras-disclaimer', true, {maxAge: 3600})
    window.scrollTo(0, 0)
  }

  return (
    <Container className="disclaimer">
      <div className="disclaimer-lead">
        <p>
          The website presents an indicative, non-exhaustive, and regularly updated list of conflict-affected and high-risk areas (CAHRAs) (as defined under Regulation 2017/821). This website was developed with funding from the European Union through call for tenders N° <i>TRADE2018/G3/G11 (Revised)</i>.
        </p>
        <p>
          This website and its underlying project are to be understood in the broader context of the European Commission's <a href={'https://ec.europa.eu/trade/whatwedo/work/index_en.htm'} target={'_blank'} rel='noopener noreferrer'>work on trade in general</a> and <a href={'http://ec.europa.eu/trade/policy/in-focus/conflict-minerals-regulation/'} target={'_blank'} rel='noopener noreferrer'>responsible sourcing of minerals in particular</a>. The objective of this project and of the CAHRAs list presented on this website is to facilitate due diligence efforts of European Union importers of minerals and metals falling under Regulation (EU) 2017/821. Information available through this website should be seen as indicative, not exhaustive, and as constituting only a part of, and is not intended to substitute and cannot replace, the broader due diligence efforts required to be conducted by relevant organisations and companies. Therefore, no reliance can and should be placed on the use of this website in respect of contributing to or meeting a person, organisation or company’s legal obligations in relation to Regulation (EU) 2017/821.
        </p>
        <p>
          It must be noted that importers sourcing from areas which are not mentioned on the list presented on this website remain responsible for complying with the due diligence obligations under Regulation 2017/821. Neither RAND Europe nor the European Commission (as contracting authority) assume any responsibility or liability in relation to the contents of this website nor the use of those contents. Equally, the inclusion of an area in the website’s CAHRAs list does not prohibit, imply, or suggest that business activities should not be conducted in this area, assuming that due diligence obligations are properly fulfilled.
        </p>
        <p>
          The information and views set out in this website are those of the authors and do not necessarily reflect the official opinion of the European Commission. Neither RAND Europe nor the Commission guarantee the accuracy of the data included in this list. Neither the RAND Europe, the Commission nor any person acting on its behalf may be held responsible for the use which may be made of the information contained therein.
        </p>
        <p>
          This website and its reports are not official documents. Care has been taken to ensure the accuracy of the information contained within the website and the indicative, non-exhaustive list, but no responsibility can be accepted by the European Commission, RAND Europe, or the data providers for any inadvertent errors or omissions. All warranties are excluded to fullest extent permitted by law. Any information in this website not owned by the European Commission may only be re-used with the permission of the applicable content owner.
        </p>
        <p>
          The boundaries, names and designations on this website’s maps do not imply endorsement or acceptance by RAND Europe or the European Commission.
        </p>
      </div>

      <div className="disclaimer-eula">
        <div className="disclaimer-accept">
          <Checkbox
            className="accept-disclaimer"
            checked={acceptedDisclaimer}
            label="I have read and understood this"
            onClick={() => toggleAcceptedDisclaimer(!acceptedDisclaimer)}
          />
        </div>
        <div className="disclaimer-button">
          <Button
            content="Access website"
            disabled={!acceptedDisclaimer}
            onClick={handleAcceptDisclaimer}
            size="huge"
          />
        </div>
      </div>
    </Container>
  )
}

export default Disclaimer
