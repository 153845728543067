import React from 'react'

import Page from 'components/Page'
import './style.scss'

function CookiePolicy() {
  return (
    <Page id="cookie-policy">
      <Page.Header>COOKIE POLICY</Page.Header>
      <Page.Content>

        <Page.Section className="copyright">
          <p>
            RAND Corporation uses cookies to recognise your repeated visits and record your agreement to the Terms of Use for this website. By clicking accept here you accept this use of such cookies.
          </p>
        </Page.Section>

      </Page.Content>
    </Page>
  )
}

export default CookiePolicy
